import React from 'react';

export const Star = (): JSX.Element => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7808 2.1365L16.5376 7.6518C16.7497 8.07102 17.1473 8.35923 17.6112 8.42473L23.761 9.30247C24.9406 9.47277 25.4045 10.9007 24.5563 11.713L20.1029 16.0099C19.7716 16.3374 19.6125 16.809 19.6921 17.2676L20.7391 23.3331C20.9379 24.4859 19.7186 25.3637 18.6582 24.8265L13.1578 21.9706C12.747 21.7479 12.2433 21.7479 11.8324 21.9706L6.33205 24.8265C5.28499 25.3768 4.05237 24.4859 4.25118 23.3331L5.29824 17.2676C5.37776 16.809 5.21872 16.3374 4.88737 16.0099L0.434032 11.7261C-0.414223 10.9138 0.0496664 9.48587 1.22927 9.31557L7.37911 8.43783C7.843 8.37233 8.25388 8.08412 8.45269 7.66491L11.2095 2.1365C11.7397 1.08846 13.2506 1.08846 13.7808 2.1365Z"
        fill="#1A3627"
      />
      <mask id="path-2-inside-1_0_1" fill="white">
        <path d="M13.7808 0.786029L16.5 6.31443C16.7121 6.73365 17.1473 7.00876 17.6112 7.07426L23.761 7.95199C24.9406 8.1223 25.4045 9.55025 24.5563 10.3625L20.1029 14.6594C19.7716 14.987 19.6125 15.4586 19.6921 15.9171L20.7391 21.9826C20.9379 23.1355 19.7186 24.0132 18.6582 23.4761L13.1578 20.6202C12.747 20.3974 12.2433 20.3974 11.8324 20.6202L6.33205 23.4761C5.28499 24.0263 4.05237 23.1355 4.25118 21.9826L5.29824 15.9171C5.37776 15.4586 5.21872 14.987 4.88737 14.6594L0.434032 10.3756C-0.414223 9.56335 0.0496664 8.1354 1.22927 7.96509L7.37911 7.08736C7.843 7.02186 8.25388 6.73365 8.45269 6.31443L11.2095 0.786029C11.7397 -0.26201 13.2506 -0.26201 13.7808 0.786029Z" />
      </mask>
      <path
        d="M13.7808 0.786029L16.5 6.31443C16.7121 6.73365 17.1473 7.00876 17.6112 7.07426L23.761 7.95199C24.9406 8.1223 25.4045 9.55025 24.5563 10.3625L20.1029 14.6594C19.7716 14.987 19.6125 15.4586 19.6921 15.9171L20.7391 21.9826C20.9379 23.1355 19.7186 24.0132 18.6582 23.4761L13.1578 20.6202C12.747 20.3974 12.2433 20.3974 11.8324 20.6202L6.33205 23.4761C5.28499 24.0263 4.05237 23.1355 4.25118 21.9826L5.29824 15.9171C5.37776 15.4586 5.21872 14.987 4.88737 14.6594L0.434032 10.3756C-0.414223 9.56335 0.0496664 8.1354 1.22927 7.96509L7.37911 7.08736C7.843 7.02186 8.25388 6.73365 8.45269 6.31443L11.2095 0.786029C11.7397 -0.26201 13.2506 -0.26201 13.7808 0.786029Z"
        fill="#FFD66B"
        stroke="#1A3627"
        strokeWidth="2"
        mask="url(#path-2-inside-1_0_1)"
      />
    </svg>
  );
};
