import z from 'zod';

const recordSchema = z.object({
  total: z.number(),
  isUnlocked: z.boolean(),
  starsAwarded: z.number(),
  module: z.object({
    id: z.string(),
    name: z.string(),
    pointsThreshold: z.number(),
    stream: z.string(),
  }),
});

const playerHistorySchema = z.object({
  userId: z.string(),
  records: z.record(recordSchema),
});

const allPlayerHistorySchema = z.record(playerHistorySchema);

export type ModuleRecord = z.infer<typeof recordSchema>;
export type PlayerHistory = z.infer<typeof playerHistorySchema>;
export type AllPlayerHistory = z.infer<typeof allPlayerHistorySchema>;

const storageKey = 'history';

function save(history: AllPlayerHistory) {
  localStorage.setItem(storageKey, JSON.stringify(history));
}

function createNewHistory(): AllPlayerHistory {
  const newHistory = {};
  save(newHistory);

  return newHistory;
}

function parseBlob(blob: string | null): AllPlayerHistory | null {
  if (blob == null) {
    return null;
  }
  try {
    return JSON.parse(blob);
  } catch (error) {
    return null;
  }
}

function getAllPlayerHistory(): AllPlayerHistory {
  function parseHistoryBlob(blob: string | null) {
    const parseResult = allPlayerHistorySchema.safeParse(parseBlob(blob));
    if (parseResult.success === false) {
      return createNewHistory();
    }
    return parseResult.data;
  }

  return parseHistoryBlob(localStorage.getItem(storageKey));
}

function getHistory(userId: string): PlayerHistory {
  const allHistory = getAllPlayerHistory();

  if (userId in allHistory) {
    return allHistory[userId];
  }
  const playerHistory = { userId, records: {} };
  allHistory[userId] = playerHistory;
  save(allHistory);

  return playerHistory;
}

function getRecord(
  history: PlayerHistory,
  moduleId: string,
): ModuleRecord | null {
  return history.records[moduleId];
}

function getTotalPoints(history: PlayerHistory): number {
  let total = 0;
  for (const key of Object.keys(history.records)) {
    total += history.records[key].total;
  }
  return total;
}

type RecordResultSuccess = {
  isSuccess: true;
  history: PlayerHistory;
};

type RecordResultFailure = {
  isSuccess: false;
  reason: 'NOT_ENOUGH_POINTS' | 'UNRECOGNIZED_LEVEL';
};

type RecordResult = RecordResultSuccess | RecordResultFailure;

function createRecord(
  history: PlayerHistory,
  levelResult: {
    levelId: string;
    total: number;
  },
): RecordResult {
  const level = history.records[levelResult.levelId];

  if (level == null) {
    return { isSuccess: false, reason: 'UNRECOGNIZED_LEVEL' };
  }
  if (level.total < levelResult.total) {
    history.records[levelResult.levelId] = {
      ...level,
      total: levelResult.total,
      starsAwarded: 3,
    };
    const allHistory = getAllPlayerHistory();
    allHistory[history.userId] = history;
    save(allHistory);

    return { isSuccess: true, history };
  }

  return { isSuccess: false, reason: 'NOT_ENOUGH_POINTS' };
}

function getAllIds(history: PlayerHistory): string[] {
  return Object.keys(history.records).map(
    key => history.records[key].module.id,
  );
}

export const History = {
  getHistory,
  getRecord,
  createRecord,
  getTotalPoints,
  getAllIds,
};
