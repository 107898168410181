import { Module as ModuleConfig } from '../../types';

function isLocked(module: ModuleConfig, moduleIds: string[]): boolean {
  if (moduleIds.some(id => module.Requirements.toString() === id)) {
    return false;
  }
  if (module.Requirements == null) {
    return false;
  }
  return true;
}

export const Module = {
  isLocked,
};
