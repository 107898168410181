import React from 'react';

export const Lock = (): JSX.Element => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_69_3269)">
        <path
          opacity="0.2"
          d="M12.9939 5.5H2.9939C2.71775 5.5 2.4939 5.72386 2.4939 6V13C2.4939 13.2761 2.71775 13.5 2.9939 13.5H12.9939C13.27 13.5 13.4939 13.2761 13.4939 13V6C13.4939 5.72386 13.27 5.5 12.9939 5.5Z"
          fill="#1A3627"
        />
        <path
          d="M12.9939 5.5H2.9939C2.71775 5.5 2.4939 5.72386 2.4939 6V13C2.4939 13.2761 2.71775 13.5 2.9939 13.5H12.9939C13.27 13.5 13.4939 13.2761 13.4939 13V6C13.4939 5.72386 13.27 5.5 12.9939 5.5Z"
          stroke="#1A3627"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.4939 5.5V3.5C5.4939 2.83696 5.75729 2.20107 6.22613 1.73223C6.69497 1.26339 7.33086 1 7.9939 1C8.65694 1 9.29282 1.26339 9.76166 1.73223C10.2305 2.20107 10.4939 2.83696 10.4939 3.5V5.5"
          stroke="#1A3627"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_3269">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.00610352)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
