import React, { useCallback } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { Button, Flex, Label } from 'workspace-core-ui';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import css from '@styled-system/css';
import { actions as routeActions } from '@slices/routeSlice';
import { WINDOW_HASH } from '@sharedConstants';
import { gameHasStarted, playSound } from '@slices/gameStateSlice';
import { logItem } from '@slices/loggingSlice';
import routeMap from '@content/routemap.json';
import { getLocalStoreUserId } from '@utils/localStore';
import { selectProfile, setCurrentModule } from '../module/profile-slice';
import { Module as ModuleConfig } from '../../types';
import { History } from '../module/history';
import { Module } from '../module/module';
import { Star } from './Star';
import { Lock } from './Lock';

const ModuleButton = styled(Button).attrs({
  forwardedAs: Link,
})(
  css({
    alignSelf: 'center',
    textDecoration: 'none',
    my: 2,
  }),
);

type ModuleOptionProps = {
  module: ModuleConfig;
};

export const ModuleOption = (props: ModuleOptionProps): JSX.Element => {
  const { allRoutes } = useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const history = History.getHistory(getLocalStoreUserId());
  const record = History.getRecord(history, props.module.Id);
  const isLocked =
    record == null && Module.isLocked(props.module, History.getAllIds(history));

  return (
    <ModuleButton
      style={{ width: '400px' }}
      data-cy="start"
      state={{ cameFromEnd: false }}
      isDisabled={isLocked}
      to={allRoutes[0].url + WINDOW_HASH}
      onPress={() => {
        dispatch(
          setCurrentModule({
            moduleName: props.module.Name,
          }),
        );
        dispatch(
          routeActions.setCurrentStream({
            streamName: props.module.Stream,
          }),
        );
        dispatch(
          routeActions.initializeRoutes({
            routes: routeMap[props.module.Stream],
            currentStreamName: props.module.Stream,
            profile,
          }),
        );
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'click',
            target: `${props.module.Name} button`,
          }),
        );
        playSound('Button');
        dispatch(gameHasStarted());
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" width="300px">
        <Label style={{ paddingRight: '15px' }}>{props.module.Name}</Label>
        {isLocked ? <Lock /> : <Star />}
      </Flex>
    </ModuleButton>
  );
};
